import type { LocationQuery, RouteLocationNormalized } from 'vue-router'

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  // eslint-disable-next-line node/prefer-global/process
  if (process.server)
    return

  const queryParamsAll: LocationQuery = to.query

  // MAIN page
  const toPath = to.path

  if (toPath === '/') {
    if (queryParamsAll.t) { // TODO: when redirecting from /login (t - tab number)
      to.query = {}
      return navigateTo(to)
    }
  }
})
