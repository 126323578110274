import type { ThemeTypes } from '~/plugins/theme/ThemeType'

const DARK_THEME: ThemeTypes = {
  name: 'DARK_BLUE_THEME',
  dark: true,
  variables: {
    'border-color': '#333F55',
  },
  colors: {
    primary: '#539BFF',
    accent: '#FFD43C',
    secondary: '#a08f42',

    background: '#171c23',
    surface: '#171c23',

    info: '#01C0C8',
    success: '#13DEB9',
    warning: '#FFAE1F',
    error: '#FA896B',

    lightprimary: '#003638',
    lightsecondary: '#40241C',
    lightsuccess: '#1B3C48',
    lighterror: '#4B313D',
    lightwarning: '#4D3A2A',

    bordercolor: '#333F55',
    inputborder: '#465670',
    hovercolor: '#333f55',
    onSurfaceVariant: '#171c23',
  },
}

export { DARK_THEME }
