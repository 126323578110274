import { default as adminfFNQjEaNkzMeta } from "/home/runner/work/smz/smz/frontend/pages/admin.vue?macro=true";
import { default as indexBLxuP6UjKXMeta } from "/home/runner/work/smz/smz/frontend/pages/card/[_id]/index.vue?macro=true";
import { default as indexfeGHbRRqzGMeta } from "/home/runner/work/smz/smz/frontend/pages/chat/index.vue?macro=true";
import { default as indexMiUqGLeFmgMeta } from "/home/runner/work/smz/smz/frontend/pages/index.vue?macro=true";
import { default as indexm2H2cF7EGYMeta } from "/home/runner/work/smz/smz/frontend/pages/login/index.vue?macro=true";
import { default as index8pzrPYjsqEMeta } from "/home/runner/work/smz/smz/frontend/pages/personal/address/index.vue?macro=true";
import { default as indexeXwvKinB1bMeta } from "/home/runner/work/smz/smz/frontend/pages/personal/index.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminfFNQjEaNkzMeta || {},
    component: () => import("/home/runner/work/smz/smz/frontend/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: "card-_id",
    path: "/card/:_id()",
    component: () => import("/home/runner/work/smz/smz/frontend/pages/card/[_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "chat",
    path: "/chat",
    meta: indexfeGHbRRqzGMeta || {},
    component: () => import("/home/runner/work/smz/smz/frontend/pages/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/smz/smz/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: indexm2H2cF7EGYMeta || {},
    component: () => import("/home/runner/work/smz/smz/frontend/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "personal-address",
    path: "/personal/address",
    component: () => import("/home/runner/work/smz/smz/frontend/pages/personal/address/index.vue").then(m => m.default || m)
  },
  {
    name: "personal",
    path: "/personal",
    meta: indexeXwvKinB1bMeta || {},
    component: () => import("/home/runner/work/smz/smz/frontend/pages/personal/index.vue").then(m => m.default || m)
  }
]