import { useSessionStorage } from '@vueuse/core'
import Base128 from 'base128-encoding'
import type { TokenInfo } from '~/api/auth/models/token-info'
import type { User } from '~/api/auth/models/user'

const USER_STORAGE_KEY = 'me'
const TOKEN_COOKIE_KEY = 'auth._token.refresh'
const TOKEN_REFRESH_COOKIE_KEY = 'auth._refresh_token.refresh'

const userEncodedStorage = useSessionStorage<string | undefined | null>(USER_STORAGE_KEY, null)

export function useUser() {
  return useState<User | undefined | null>('user', () => meFromStorage())
}

export function targetSecuredUrlPathFromHistory() {
  return useState<string>('targetSecuredUrlPathFromHistory', () => '/')
}

export async function getToken(): Promise<string | undefined> {
  const token = useCookie(TOKEN_COOKIE_KEY).value
  const tokenRefresh = useCookie(TOKEN_REFRESH_COOKIE_KEY).value
  if (!tokenRefresh && !token)
    return undefined

  if (!tokenRefresh) {
    setUser(undefined)
    await navigateTo('/login')
    return undefined
  }
  else if (!token) {
    try {
      const refreshToken: string | null | undefined = useCookie(TOKEN_REFRESH_COOKIE_KEY).value

      const tokenInfo: TokenInfo = await $fetch<TokenInfo>(`${useRuntimeConfig().public.API_URL}/auth/refresh`, {
        headers: { ...useRequestHeaders([`cookie`]) as HeadersInit },
        method: `POST`,
        body: { refreshToken },
      })

      await updateTokens(tokenInfo)

      return tokenInfo.accessToken
    }
    catch (e) {
      console.error('❌Error refreshing token 🔴', e)
      return undefined
    }
  }
  return token!
}

export function setUser(userOrNull: User | undefined) {
  useUser().value = userOrNull
  if (!userOrNull) {
    console.debug(`Logging out user...`)
    userEncodedStorage.value = null
    useCookie(TOKEN_COOKIE_KEY).value = null
    useCookie(TOKEN_REFRESH_COOKIE_KEY).value = null
  }
  else {
    userEncodedStorage.value = Base128.encodeStr(JSON.stringify(userOrNull))
  }
}

export async function updateTokens(tokenInfo: TokenInfo) {
  useCookie(TOKEN_COOKIE_KEY, { maxAge: tokenInfo.expiresIn }).value = tokenInfo.accessToken
  useCookie(TOKEN_REFRESH_COOKIE_KEY, { maxAge: tokenInfo.refreshTokenExpiresIn }).value = tokenInfo.refreshToken
}

export function hasRole(role: string) {
  const scope = useUser().value?.scope
  if (!scope || scope.length === 0)
    return false
  return new Set(scope).has(role.trim().toUpperCase())
}

// PRIVATE functions:
function meFromStorage(): undefined | User {
  const jsonEncoded = userEncodedStorage.value
  if (!jsonEncoded)
    return undefined
  const user: User = JSON.parse(Base128.decodeStr(jsonEncoded))
  return user
}
