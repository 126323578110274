import type { ThemeTypes } from '~/plugins/theme/ThemeType'

const LIGHT_THEME: ThemeTypes = {
  name: 'BLUE_THEME',
  dark: false,
  variables: {
    'border-color': '#e5eaef',
  },
  colors: {
    primary: '#5D87FF',
    secondary: '#49BEFF',
    background: '#eef5f9',

    info: '#539BFF',
    success: '#13DEB9',
    accent: '#FFAB91',
    warning: '#FFAE1F',
    error: '#FA896B',

    lightprimary: '#ECF2FF',
    lightsecondary: '#E8F7FF',
    lightsuccess: '#E6FFFA',
    lighterror: '#FDEDE8',
    lightwarning: '#FEF5E5',
    bordercolor: '#e5eaef',

    inputborder: '#DFE5EF',
    hovercolor: '#f6f9fc',
    surface: '#fff',
    onSurfaceVariant: '#fff',
  },
}

export { LIGHT_THEME }
