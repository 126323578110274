import revive_payload_client_w017ffPScp from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sXgK0F5uHA from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_L8ap93uvSS from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_WhWwqT87ym from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LMtgRAk5OS from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yS3zqI0pO0 from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_cto2j1x9dp from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_khrYlSZKcB from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Z0cQRPeVZC from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/smz/smz/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_EzuccCaDjm from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_ztub34VNDX from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@testing-library+vue@8.1.0_@vue+compiler-sfc@3.4.3_lo2dfdfwutaf35aookyouvlsrq/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import _01_tabler_icons_9mYbrP432H from "/home/runner/work/smz/smz/frontend/plugins/01.tabler-icons.ts";
import _02_vuetify_rwZ6VQqJQJ from "/home/runner/work/smz/smz/frontend/plugins/02.vuetify.ts";
import scrollbar_client_afVkuO58C3 from "/home/runner/work/smz/smz/frontend/plugins/scrollbar.client.ts";
import user_client_pgylpKXiFi from "/home/runner/work/smz/smz/frontend/plugins/user.client.ts";
export default [
  revive_payload_client_w017ffPScp,
  unhead_sXgK0F5uHA,
  router_L8ap93uvSS,
  payload_client_WhWwqT87ym,
  navigation_repaint_client_LMtgRAk5OS,
  check_outdated_build_client_yS3zqI0pO0,
  view_transitions_client_cto2j1x9dp,
  chunk_reload_client_khrYlSZKcB,
  plugin_vue3_Z0cQRPeVZC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_EzuccCaDjm,
  plugin_ztub34VNDX,
  _01_tabler_icons_9mYbrP432H,
  _02_vuetify_rwZ6VQqJQJ,
  scrollbar_client_afVkuO58C3,
  user_client_pgylpKXiFi
]