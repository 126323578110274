import validate from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/pages/runtime/validate.js";
import always_45run_45global from "/home/runner/work/smz/smz/frontend/middleware/always-run.global.ts";
import manifest_45route_45rule from "/home/runner/work/smz/smz/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__35jas24kmsegcm7mys5mcah63q/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  always_45run_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-only": () => import("/home/runner/work/smz/smz/frontend/middleware/admin-only.ts"),
  "guest-only": () => import("/home/runner/work/smz/smz/frontend/middleware/guest-only.ts"),
  "user-only": () => import("/home/runner/work/smz/smz/frontend/middleware/user-only.ts")
}